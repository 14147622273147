import React from 'react'
import styles from '../../assets/css/util.module.css';
const OnLoadGrid = () => {
    return (
        <>
        <div className={styles.ldsgrid}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>          
        </>
    )
}

export default OnLoadGrid;